import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import Janus from '@services/janus';

import { BaseLogging } from '@base/base-logging';
import { Tag, stopAllTracks } from '@models';
import { StoreService } from '@services/store.service';

export type VideoServiceConf = {
    roomId: number;
    roomPin?: string;
    token?: string;
    userName: string;
};

@Injectable()
@Tag('VideoService')
export class VideoService extends BaseLogging {


    readonly onRoomJoined: Subject<any> = new Subject();
    readonly onRoomDestroyed: Subject<void> = new Subject();

    readonly onLocalStream: Subject<MediaStream | undefined> = new Subject();

    readonly onFeedAdd: Subject<any> = new Subject();
    readonly onFeedUpdate: Subject<any> = new Subject();
    readonly onFeedRemove: Subject<string> = new Subject();
    readonly onCleanUp: Subject<void> = new Subject();
    readonly onFeedMessage: Subject<{ id: string, type: string, payload: any }> = new Subject();

    readonly webRtcState: Subject<boolean> = new Subject();
    readonly mediaState: Subject<{ medium: 'audio' | 'video', state: boolean }> = new Subject();
    readonly iceState: Subject<RTCIceConnectionState> = new Subject();

    janus?: any;
    vrHandle: any;
    userId?: string;
    userToken?: string;
    serverUrl?: string
    conf?: VideoServiceConf;

    jId?: number;
    jPrivateId?: number;
    myStream?: MediaStream;
    feedHandles: { [id: string]: any } = {};

    constructor(private _store: StoreService) {
        super();
    }

    init(serverUrl: string, userToken: string): Observable<any> {
        this.userId = this._store.getState('user').userId;
        this.serverUrl = serverUrl;
        this.userToken = userToken;
        return new Observable(obs => {
            Janus.init({
                debug: 'all',
                callback: () => {
                    if (!Janus.isWebrtcSupported()) {
                        obs.error('WebRTC is not supported');
                    }
                    this.janus = new (Janus as any)({
                        server: serverUrl,
                        success: () => {
                            // this._W('init', 'Janus success', this.janus);
                            obs.next(this.janus);
                            obs.complete();
                        },
                        error: (err: any) => {
                            this._W('init', 'Janus error:', err);
                            obs.error(this.unwrapErr(err));
                        },
                        destroyed: () => {
                            this._W('init', 'Janus destroyed');
                            obs.error('Данная видеокомната была удалена.');
                            this.onRoomDestroyed.next();
                        }
                    });
                }
            });
        });
    }

    clear(): void {
        this.myStream = undefined;
        this.onLocalStream.next(undefined);
        this.feedHandles = {};
        this.onCleanUp.next();
    }

    attach2VRP(cfg: VideoServiceConf): Observable<any> {
        this.conf = cfg;
        // this._W('attach2VRP', 'config:', cfg);
        return new Observable(obs => {
            this.janus.attach({
                plugin: 'janus.plugin.videoroom',
                opaqueId: this.userToken,
                token: cfg.token,
                success: (handle: any) => {
                    this._L('attach2VRP', 'Plugin attached! (' + handle.getPlugin() + ', id=' + handle.getId() + ')');
                    this.vrHandle = handle;
                    handle.send({
                        message: {
                            request: 'join',
                            room: cfg.roomId,
                            pin: cfg.roomPin,
                            token: cfg.token,
                            ptype: 'publisher',
                            display: this.userId + '|' + cfg.userName
                        }
                    });
                    obs.next(handle);
                    obs.complete();
                },
                error: (err: any) => {
                    this._W('attach2VRP', 'Error attaching plugin:', err);
                    obs.error('Error attaching plugin:' + this.unwrapErr(err))
                },
                consentDialog: (on: any) => {
                    this._L('attach2VRP', 'consentDialog: should be ' + (on ? 'on' : 'off') + ' now');
                },
                iceState: (state: RTCIceConnectionState) => {
                    // this._L('attach2VRP', 'iceState:', state);
                    this.iceState.next(state);
                },
                mediaState: (medium: any, on: any) => {
                    this._L('attach2VRP', 'mediaState: Janus ' + (on ? 'started' : 'stopped') + ' receiving our ' + medium);
                    this.mediaState.next({ medium, state: on });
                },
                webrtcState: (on: boolean) => {
                    // this._W('attach2VRP', 'webrtcState:', on);
                    this.webRtcState.next(on);
                },
                onmessage: (msg: any, jsep: RTCSessionDescription) => {
                    this._L('attach2VRP', 'onmessage:', msg);
                    const event = msg.videoroom;
                    if (event) {
                        if (event === 'joined') {
                            // Publisher/manager created, negotiate WebRTC and attach to existing feeds, if any
                            this.jId = msg.id;
                            this.jPrivateId = msg.private_id;
                            this._L('attach2VRP', 'Successfully joined room ' + msg.room + ' with ID ' + this.jId);
                            this.onRoomJoined.next(msg);
                            // this.publishOwnFeed().subscribe({ error: err => this._W('attach2VRP', 'publishOwnFeed.error', err) });

                            // Any new feed to attach to?
                            if (msg.publishers) {
                                const list = msg.publishers;
                                // this._L('attach2VRP', 'Got a list of available publishers/feeds:', list);
                                for (const f of list) {
                                    this.newRemoteFeed(f.id, f.display, f.audio_codec, f.video_codec).subscribe({ error: err => this._W('attach2VRP', 'newRemoteFeed.error', err) });
                                }
                            }
                        }
                        else if (event === 'destroyed') {
                            this.onRoomDestroyed.next();
                        }
                        else if (event === 'event') {
                            if (msg.publishers) {
                                const list = msg.publishers;
                                // this._L('attach2VRP', 'Got a list of available publishers/feeds:', list);
                                for (const f of list) {
                                    this.newRemoteFeed(f.id, f.display, f.audio_codec, f.video_codec).subscribe({ error: err => this._W('attach2VRP', 'newRemoteFeed2.error', err) });
                                }
                            }
                            else if (msg.leaving) {
                                // One of the publishers has gone away?
                                const fid = msg.leaving;
                                // this._L('attach2VRP', 'Publisher left:', fid);
                                if (this.feedHandles[fid]) {
                                    this.feedHandles[fid].detach();
                                    delete this.feedHandles[fid];
                                    this.onFeedRemove.next(fid);
                                }
                            }
                            else if (msg.unpublished) {
                                // One of the publishers has unpublished?
                                const fid = msg.unpublished;
                                // this._L('attach2VRP', 'Publisher unpublished:', fid);
                                if (fid === 'ok') {
                                    // That's us
                                    this.myStream = undefined;
                                    this.onLocalStream.next(undefined);
                                    // this.vrHandle.hangup();
                                    return;
                                }
                                if (this.feedHandles[fid]) {
                                    this.feedHandles[fid].detach();
                                    delete this.feedHandles[fid];
                                    this.onFeedRemove.next(fid);
                                }
                            }
                            else if (msg.error) {
                                this._L('attach2VRP', 'msg.error:', msg.error);
                                // obs.error(msg.error);
                                // if (msg.error_code === 426) {
                                // } else {
                                // }
                            }
                        }
                    }
                    if (jsep) {
                        // this._L('attach2VRP', 'Handling SDP as well...', jsep);
                        this.vrHandle.handleRemoteJsep({ jsep });
                        // Check if any of the media we wanted to publish has
                        // been rejected (e.g., wrong or unsupported codec)
                        // const audio = msg.audio_codec;
                        //     if (mystream && mystream.getAudioTracks() && mystream.getAudioTracks().length > 0 && !audio) {
                        //         // Audio has been rejected
                        //         toastr.warning('Our audio stream has been rejected, viewers won\'t hear us');
                        //     }
                        //     const video = msg.video_codec;
                        //     if (mystream && mystream.getVideoTracks() && mystream.getVideoTracks().length > 0 && !video) {
                        //         // Video has been rejected
                        //         toastr.warning('Our video stream has been rejected, viewers won\'t see us');
                        //         // Hide the webcam video
                        //         $('#myvideo').hide();
                        //         $('#videolocal').append(
                        //             '<div class='no-video-container'>' +
                        //             '<i class='fa fa-video-camera fa-5 no-video-icon' style='height: 100%;'></i>' +
                        //             '<span class='no-video-text' style='font-size: 16px;'>Video rejected, no webcam</span>' +
                        //             '</div>');
                        //     }
                    }
                },
                onlocalstream: (stream: MediaStream) => {
                    // this._L('attach2VRP', 'onlocalstream:', stream);
                    this.myStream = stream; // .clone();
                    this.onLocalStream.next(this.myStream);
                },
                onremotestream: (stream: any) => {
                    // The publisher stream is sendonly, we don't expect anything here
                    // this._W('attach2VRP', 'onremotestream:', stream);
                },
                oncleanup: () => {
                    // this._W('attach2VRP', 'oncleanup');
                    this.myStream = undefined;
                    this.onLocalStream.next(undefined);
                    this.feedHandles = {};
                    this.onCleanUp.next();
                    obs.complete();
                }
            });
        });
    }

    publishOwnFeed(): Observable<RTCSessionDescription> {
        // this._W('publishOwnFeed');
        const media: any = { audioRecv: false, videoRecv: false, audioSend: true, videoSend: false, data: true };
        return this.publishOwnFeedByMedia(media);
    }

    publishOwnFeedByMedia(media: any): Observable<RTCSessionDescription> {
        // this._W('publishOwnFeedByMedia', media);
        return new Observable<RTCSessionDescription>(obs => {
            this.vrHandle.createOffer({
                // Add data:true here if you want to publish datachannels as well
                media,
                simulcast: true,
                success: (jsep: RTCSessionDescription) => {
                    // this._L('publishOwnFeedByMedia', 'success:', jsep);
                    const publish = { request: 'configure', audio: true, video: media.videoSend };
                    // You can force a specific codec to use when publishing by using the
                    // audiocodec and videocodec properties, for instance:
                    // 		publish.audiocodec = 'opus'
                    // to force Opus as the audio codec to use, or:
                    // 		publish.videocodec = 'vp9'
                    // to force VP9 as the videocodec to use. In both case, though, forcing
                    // a codec will only work if: (1) the codec is actually in the SDP (and
                    // so the browser supports it), and (2) the codec is in the list of
                    // allowed codecs in a room. With respect to the point (2) above,
                    // refer to the text in janus.plugin.videoroom.jcfg for more details.
                    // We allow people to specify a codec via query string, for demo purposes
                    this.vrHandle.send({ message: publish, jsep });
                    obs.next(jsep);
                    obs.complete();
                },
                error: (error: any) => {
                    this._W('publishOwnFeedByMedia', 'error:', error);
                    obs.error('WebRTC error. ' + this.unwrapErr(error));
                }
            });
        });
    }

    publishOwnStream(stream: MediaStream, replaceAudio = true, replaceVideo = true): Observable<RTCSessionDescription> {
        // this._W('publishOwnFeedByStream', stream, '\n\t\taudio:', stream.getAudioTracks(), '\n\t\tvideo:', stream.getVideoTracks());
        return new Observable<RTCSessionDescription>(obs => {
            this.vrHandle.createOffer({
                media: { audioRecv: false, videoRecv: false, audioSend: true, videoSend: true, replaceAudio, replaceVideo, data: true },
                stream,
                simulcast: true,
                success: (jsep: RTCSessionDescription) => {
                    this._L('publishOwnFeedByStream', 'success:', jsep);
                    const publish = { request: 'configure', audio: true, video: true };
                    this.vrHandle.send({ message: publish, jsep });
                    obs.next(jsep);
                    obs.complete();
                },
                error: (error: any) => {
                    this._W('publishOwnFeedByStream', 'error:', error);
                    obs.error('WebRTC error. ' + this.unwrapErr(error));
                }
            });
        });
    }

    unpublishOwnFeed(): void {
        if (this.vrHandle) {
            this.vrHandle.send({ message: { request: 'unpublish' } });
        }
    }

    toggleMute(): boolean {
        const muted = this.vrHandle.isAudioMuted();
        this._L('toggleMute', (muted ? 'Unmuting' : 'Muting') + ' local stream...');
        if (muted) {
            this.vrHandle.unmuteAudio();
        }
        else {
            this.vrHandle.muteAudio();
        }
        return this.vrHandle.isAudioMuted();
    }

    newRemoteFeed(id: string, _display: string, _audio: string, video: string): Observable<any> {
        return new Observable<any>(obs => {
            let phRemote: any = null;
            this.janus.attach({
                plugin: 'janus.plugin.videoroom',
                opaqueId: this.userToken,
                success: (handle: any) => {
                    phRemote = handle;
                    phRemote.simulcastStarted = false;
                    this._L('newRemoteFeed', 'Plugin attached! (' + phRemote.getPlugin() + ', id=' + phRemote.getId() + ')');
                    this._L('newRemoteFeed', '  -- This is a subscriber');
                    // We wait for the plugin to send us an offer
                    const subscribe: any = {
                        request: 'join',
                        room: this.conf!.roomId,
                        pin: this.conf!.roomPin,
                        ptype: 'subscriber',
                        feed: id,
                        private_id: this.jPrivateId
                    };
                    // In case you don't want to receive audio, video or data, even if the
                    // publisher is sending them, set the 'offer_audio', 'offer_video' or
                    // 'offer_data' properties to false (they're true by default), e.g.:
                    // 		subscribe.offer_video = false;
                    // For example, if the publisher is VP8 and this is Safari, let's avoid video
                    // TODO
                    // if (Janus.webRTCAdapter.browserDetails.browser === 'safari' &&
                    //         (video === 'vp9' || (video === 'vp8' && !Janus.safariVp8))) {
                    //     if (video)
                    //         video = video.toUpperCase()
                    //     // toastr.warning('Publisher is using ' + video + ', but Safari doesn\'t support it: disabling video');
                    //     subscribe.offer_video = false;
                    // }
                    phRemote.videoCodec = video;
                    phRemote.send({ message: subscribe });
                    obs.complete();
                },
                error: (error: any) => {
                    this._W('newRemoteFeed', 'Error attaching plugin:', error);
                    obs.error('Error attaching plugin. ' + this.unwrapErr(error));
                },
                onmessage: (msg: any, jsep: any) => {
                    this._L('newRemoteFeed', ' ::: Got a message (subscriber) :::', msg);
                    const event = msg.videoroom;
                    this._L('newRemoteFeed', 'Event: ' + event);
                    this.onFeedMessage.next({ id: phRemote.rfid, type: 'message', payload: msg });
                    if (msg.error) {
                        this.onFeedMessage.next({ id: phRemote.rfid, type: 'error', payload: msg.error });
                    }
                    else if (event) {
                        if (event === 'attached') {
                            this.feedHandles[msg.id] = phRemote;
                            phRemote.rfid = msg.id;
                            if (msg.display.indexOf('|') == -1) {
                                phRemote.userName = msg.display;
                                phRemote.userId = undefined;
                            }
                            else {
                                const idName = msg.display.split('|', 2);
                                phRemote.userName = idName && idName[1] ? idName[1] : '<Неизвестно>';
                                phRemote.userId = idName && idName[0] ? idName[0] : undefined;
                            }
                            this.onFeedAdd.next(phRemote);
                            this._L('Successfully attached to feed ' + phRemote.rfid + ' (' + phRemote.rfdisplay + ') in room ' + msg.room);
                        }
                        else if (event === 'event') {
                            // Check if we got a simulcast-related event from this publisher
                            const substream = msg.substream;
                            const temporal = msg.temporal;
                            if ((substream !== null && substream !== undefined) || (temporal !== null && temporal !== undefined)) {
                                if (!phRemote.simulcastStarted) {
                                    this._W('newRemoteFeed', 'Simulcast:', phRemote.rfid, 'substream:', substream, 'temporal:', temporal, '\n\t\tphRemote:', phRemote, '\n\t\tmsg:',msg);
                                    phRemote.simulcastStarted = true;
                                    // Add some new buttons
                                    // TODO addSimulcastButtons(phRemote.rfid, phRemote.videoCodec === 'vp8');
                                }
                                // We just received notice that there's been a switch, update the buttons
                                // TODO updateSimulcastButtons(phRemote.rfid, substream, temporal);
                            }
                        }
                        else {
                            // What has just happened?
                            obs.error('newRemoteFeed.onmessage: unknown message type: ' + event);
                            this.onFeedMessage.next({ id: phRemote.rfid, type: 'unknown', payload: msg });
                        }
                    }
                    if (jsep) {
                        // this._L('newRemoteFeed', 'Handling SDP as well...', jsep);
                        // Answer and attach
                        phRemote.createAnswer({
                            jsep: jsep,
                            // Add data:true here if you want to subscribe to datachannels as well
                            // (obviously only works if the publisher offered them in the first place)
                            media: { audioSend: false, videoSend: false },	// We want recvonly audio/video
                            success: (jsep: any) => {
                                // this._L('newRemoteFeed', 'Got SDP!', jsep);
                                const body = { request: 'start', room: this.conf!.roomId };
                                phRemote.send({ message: body, jsep: jsep });
                            },
                            error: (error: any) => {
                                this._W('newRemoteFeed', 'WebRTC error:', error);
                                this.onFeedMessage.next({ id: phRemote.rfid, type: 'error', payload: msg.error });
                                obs.error('WebRTC error. ' + this.unwrapErr(msg.error));
                            }
                        });
                    }
                },
                iceState: (state: any) => {
                    // this._L('newRemoteFeed', 'iceState: ICE state of this WebRTC PeerConnection (feed #' + phRemote.rfid + ') changed to ' + state);
                    this.onFeedMessage.next({ id: phRemote.rfid, type: 'iceState', payload: state });
                },
                webrtcState: (on: any) => {
                    // this._L('newRemoteFeed', 'webrtcState: Janus says this WebRTC PeerConnection (feed #' + phRemote.rfid + ') is ' + (on ? 'up' : 'down') + ' now');
                    this.onFeedMessage.next({ id: phRemote.rfid, type: 'webrtcState', payload: on });
                },
                onlocalstream: (_stream: any) => {
                    // The subscriber stream is recvonly, we don't expect anything here
                },
                onremotestream: (stream: MediaStream) => {
                    // this._W('newRemoteFeed', 'onremotestream: Remote feed #' + phRemote.rfid + ', stream:', stream);
                    // this._L('newRemoteFeed', 'onremotestream: phRemote', phRemote);
                    if (this.feedHandles[phRemote.rfid]) {
                        this.feedHandles[phRemote.rfid].stream = stream;
                        this.onFeedUpdate.next(this.feedHandles[phRemote.rfid]);
                    }
                },
                oncleanup: () => {
                    // this._L('newRemoteFeed', ' ::: Got a cleanup notification (remote feed ' + id + ') :::');
                    if (this.feedHandles[id]) {
                        delete this.feedHandles[id];
                        this.onFeedRemove.next(id);
                    }
                }
            });
        });
    }

    changeSourceDevices(vId: string | undefined, aId: string | undefined, replace: { video?: boolean, audio?: boolean }): Observable<any> {
        // this._W('changeSourceDevices', 'vId:', vId, 'aId:', aId, 'replace:', replace);
        stopAllTracks(this.myStream);
        return new Observable<any>(obs => {
            // this.vrHandle.send({ message: { audio: true, video: true } });
            this.vrHandle.createOffer({
                // We provide a specific device ID for both audio and video
                media: {
                    audio: aId ? {
                        deviceId: {
                            exact: aId
                        }
                    } : false,
                    replaceAudio: !!replace.audio,
                    video: vId == 'screen' ? vId : (vId ? {
                        deviceId: {
                            exact: vId
                        }
                    } : false),
                    replaceVideo: !!replace.video,
                },
                simulcast: true,
                success: (jsep: any) => {
                    // this._L('changeSourceDevices', 'success: Got SDP!', jsep);
                    this.vrHandle.send({ message: { audio: true, video: true }, jsep: jsep });
                    obs.next(jsep);
                    obs.complete();
                },
                error: (err: any) => {
                    this._W('changeSourceDevices', 'error:', err);
                    obs.error(this.unwrapErr(err));
                }
            });
        });
    }

    stopAllStreams(): void {
        stopAllTracks(this.myStream);
        if (this.feedHandles) {
            Object.values(this.feedHandles).forEach(feed => stopAllTracks(feed?.stream));
        }
    }

    hangup(): void {
        if (this.vrHandle) {
            this.vrHandle.hangup();
        }
    }

    unwrapErr(err: any): string {
        return err?.name ? err.name + ': ' + err.message : err;
    }

    setFeedSubStream(rfid: string, substream: number): void {
        // this._L('setFeedSubStream', rfid, 'substream:', substream, 'feedHandle:', this.feedHandles[rfid]);
        if (this.feedHandles[rfid]) {
            this.feedHandles[rfid].send({ message: { request: "configure", substream } });
        }
    }

}
